<template>
  <div>
    <div
      size="A4"
      class="printed page-a4 container">
      <div
        v-for="(sku, index) in printItems"
        :key="index"
        style="paddint-top: 10px"
        size="30x55">
        <div class="content-header">
          <div class="img-header">
            <barcode
              :value="sku.barcode"
              font-size="12px"
              height="40" />
          </div>
        </div>
        <div
          style="left: 0px"
          class="variant">
          {{ sku.text1 }}
        </div>
        <div
          style="left: 13px"
          class="variant">
          {{ sku.text2 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueBarcode from 'vue-barcode'

export default {
  name: 'QRcodeGodexPage',
  components: {
    barcode: VueBarcode
  },
  data () {
    return {
      printItems: []
    }
  },
  async mounted () {
    const printItemString = window.localStorage.getItem('print_barcode')
    if (printItemString) {
      const printItems = []
      for (const it of JSON.parse(printItemString)) {
        for (let i = 0; i < it.amount; i++) {
          printItems.push(it)
        }
      }
      this.printItems = printItems
    }

    setTimeout(() => {
      window.print()
    }, 200)
  },
  methods: {
    priceFormat (price) {
      return parseInt(price).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    }
  }
}
</script>

<style scoped>
  /* .main {
    overflow: auto;
  } */
  /* @page {
    margin: 10%;
  } */
  div[size="A4"] {
    background: #fff;
    width: 108mm;
    min-height: 60mm;
    display: block;
    margin: 40px auto;
    overflow: auto;
    /* padding: 14mm 15mm; */
    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
    color: #000;
  }
  .content-header .variant{
    display: table-cell;
  }
  div[size="30x55"] {
    /* border: 1px solid #000; */
    position: relative;
    overflow: hidden;
    display: inline-table;
    width: 111px;
    height: 55mm;
    margin: 0 0 0 2.6mm;
    background: #fff;
    color: #000;
    font-size: 11px;
    line-height: 12px;
    font-weight: bold;
    overflow: hidden;
  }
div[size="30x55"] > div {
  width: 100%;
  height: 100%;
}
div[size="30x55"] .printed-container {
  display:flex;
  flex-wrap: wrap;
  padding-top: 0.3mm;
  min-height: 100%;
}

div[size="30x55"] .variant {
  font-weight: normal;
  width: 178px;
  height: 20px;
  top: 120px;
  overflow: hidden;
  text-align: center;
  position: absolute;
  white-space: nowrap;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
  .page-break {
    display: block;
    page-break-before: always;
    padding-top: 20px;
  }
div[size="30x55"] .img-header > div {
  top: 90px;
  left: -40px;
  position: absolute;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
</style>
