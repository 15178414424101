import { render, staticRenderFns } from "./barCodeGodexPage.vue?vue&type=template&id=528615ba&scoped=true&"
import script from "./barCodeGodexPage.vue?vue&type=script&lang=js&"
export * from "./barCodeGodexPage.vue?vue&type=script&lang=js&"
import style0 from "./barCodeGodexPage.vue?vue&type=style&index=0&id=528615ba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "528615ba",
  null
  
)

export default component.exports